
.trainingCard {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);

    border-radius: 20px;

    height: calc(100%);
    width: calc(100%);
    gap: 10px;

    padding: 15px;
    box-sizing: border-box;
}

.dateTimeRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.trainingDate {

}

.trainingTime {

}

.trainingDate, .trainingTime {
    font-size: var(--normal-text-size);
    line-height: 17px;
    color: rgba(129, 129, 129, 1);
}


.trainerName {

}

.activityName {

}

.modifyTraining {
    background: none;
    border: none;
    color: #818181;
    font-size: var(--small-text-size);
    cursor: pointer;
}