/*App.css*/
:root {
    --nav-bar-height: 50px; /* Высота навигационной панели */
    --padding-from-nav-bar: calc(var(--nav-bar-height) + 10px);
    --max-page-width: 400px;

    --page-width-padding: 1rem;
    --max-width-with-padding: calc(100% - 2 * 1rem);

    --small-text-size: 14px;
    --normal-text-size: 16px; /*Обычный размер шрифта. Должен использоваться в большинстве случаев*/
    --middle-text-size: 24px; /*Средний размер шрифта*/
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    font-family: Inter, sans-serif;
}

.main {
    margin-bottom: var(--nav-bar-height);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100%); /* Adjusts the width to be full with some padding */
}

.page {
    background-color: #e2e1e1;
    width: calc(100%);
    max-width: var(--max-page-width);
    min-height: 100vh;
}
