
.skillPageContainer {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.title {
    font-size: 23px;
    color: #434343;
    font-weight: 600;
}

.expandedBlockTitle {
    color: #434343;
    font-size: 19px;
    margin: 0 0 5px 0;
}

.workTimeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2vh;
}

.navBar {

}

.categories {
    width: calc(100%);
    max-width: var(--max-page-width);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}


.categoryContainer {

    width: calc(100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 10px;
}

.categoryHeader {
    width: calc(100%);
    border-bottom: 0.5px solid var(--black, #434343);

}

.categoryBody {
}

.subCategory {
}

.subCategoryLabel {
    font-size: var(--normal-text-size);
}

.selectedSubCategoryLabel {
    font-size: var(--normal-text-size);
    font-weight: bold;
}

.subCategoryRadio {
}


.saveButtonContainer {
}


