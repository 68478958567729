.container {
    display: flex;
    flex-direction: column;
    width: calc(100%);
    max-width: var(--max-page-width);
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(50, 50, 71, 0.06), 0 4px 4px rgba(50, 50, 71, 0.08);
    background-color: #fff;
}

.main {
    padding: 14px 16px;
}

.monthNavigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
}

.monthTitle {
    font-weight: 700;
    font-size: 18px;
    color: #434343;
    width: 100%;
}

.navigationButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 30px;
    gap: 8px;
}

.navButton {

}

.navButtonImg {
    width: 20px;
    height: 20px;
}

.weekDays {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    padding: 0 1px;
    font-size: var(--small-text-size);
    color: #6C757D;
    font-weight: 600;
    text-transform: uppercase;
}

.weekDay {
    flex-grow: 1;
    text-align: center;
}

.daysGrid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 8px;
    margin-top: 16px;
}

.day {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    background-color: #F8F9FA;
    font-size: 16px;
    color: #434343;
}
