.container {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100%);
}

.bookingContainer {
    width: calc(100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    gap: 10px;
}

.bookingButton {

}

/*Блок с фото тренера*/

.trainerPhotoContainer {
    width: calc(100%);
    max-width: var(--max-page-width);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    color: white;

    background-size: cover;
    background-position: center;
    height: 390px;

    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.panelRow {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 10px;
    box-sizing: border-box;
}

.panelIcon {
    height: 30px;
    width: 30px;
}

.trainerNameContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    background-color: rgba(0, 0, 0, 0.5);
}

.trainerName {
    font-size: var(--middle-text-size);
    font-weight: bold;
}

.ratingContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 5px;
}

.rating {
    font-size: var(--middle-text-size);
    font-weight: bold;
}

.ratingIcon {
    height: var(--middle-text-size);
    width: 100%;
}

.optionalBlocks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.expandedBlock {
    width: calc(100%);
    margin-top: 10px;
}

.expandedBlockTitle {
    color: #434343;
    font-size: 19px;
    margin: 0 0 5px 0;
}

.expandedBlockHeader {
    width: calc(100%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.expandedBlockSection {
    width: calc(100%);
}

.viewAllButton {
    background: none;
    color: #818181;
    border: none;
    font-size: 16px;
    cursor: pointer;
}


.Address {
    font-style: normal;
}

.skillTagContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;
}

.skillTag {
    background-color: #007BFF;
    color: white;
    border-radius: 20px;
    padding: 5px 15px;
}

.timeRangeContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 5px;

    justify-content: center;
    align-items: center;
}

.timeRangeTag {
    background-color: black;
    color: white;
    border-radius: 20px;
    padding: 5px 5px;
}

.timeRangeTagSelected {
    background-color: #007BFF;
}

.trainerAbout {
    font-size: var(--normal-text-size);
}
