.genderContainer {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.label {
    color: #434343;
    font-weight: 500;
    font-size: 16px;
}

.genders {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: #e2e1e1;
    color: #818181;

    border-radius: 8px;
    border: 1px solid #747171;

    padding: 8px;
    box-sizing: border-box;
}

.genderOption {
    display: flex;
}

.genderRadio {
    margin-right: 8px;
}

.genderLabel {
    font-weight: 400;
    font-size: 16px;
}