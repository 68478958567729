.home {
    margin-bottom: var(--nav-bar-height);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.searchBar {
    height: 100%;
    width: 100%;
}

.blockName {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.blockNameText {
    font-size: 24px;
    width: 100%;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categories {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    background-color: rgba(226, 225, 225, 1);

    width: calc(100%);
    max-width: var(--max-page-width);
}

.categoryRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    width: calc(100%);
}

.actionIcon {
    width: 24px;
    height: auto;
}