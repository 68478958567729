.trainersSchedulePageContainer {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.title {
    font-size: var(--middle-text-size);
    color: #434343;
    font-weight: 600;
}

.workTimeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2vh;
}

.navBar {

}

.trainingSection {
    width: calc(100%);
    max-width: var(--max-page-width);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.trainingItem {
    width: calc(100%);
}

.trainingTitle {
    color: #434343;
    font-size: 19px;
}