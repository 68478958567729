.scrollContainer {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.scrollContent {
    display: inline-block;
}

.scrollItem {
    display: inline-block;
    margin-right: 10px;
}