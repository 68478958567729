.container {
    border-radius: 20px;
    background-color: #e2e1e1;

    display: flex;
    flex-direction: column;

    width: calc(100%);
    max-width: var(--max-page-width);
}

.reviewSection {
    display: flex;
    flex-direction: column;
    gap: 9px;
}

.reviewCard {
    border-radius: 16px;
    background-color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.reviewer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewerName {
    font-weight: 600;
    margin: 0;
}

.reviewRating {
    display: flex;
    align-items: center;
}

.rating {
    margin-right: 2px;
    font-weight: 500;
}

.ratingImage {
    width: 12px;
}

.comment {
    margin-top: 8px;
    font-weight: 400;
}