.common {
    width: 250px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    border-radius: 12px;
    height: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.wideButton {
    background-color: #4169E1;
    color: #fff;
    border: none;
}

.transparent {
    color: #434343;
    background-color: transparent;
    border: 1px solid #434343;
}

.wideButton:active {
    background-color: grey;
}

.transparent:active {
    background-color: grey;
}