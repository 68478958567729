.container {
    display: flex;
    flex-direction: column;
}

.trainersContainer {
    display: flex;
    width: 100%;
    margin-top: 15px;
    gap: 8px;
}

.trainerCardContainer {
    justify-content: center;
    display: flex;
    flex-grow: 1;
    flex-basis: 0%;
    flex-direction: column;
    font-size: 16px;
    color: #000;
    font-weight: 500;
    text-align: center;
}

.trainerImage {
    aspect-ratio: 1;
    object-fit: cover;
    width: 115px;
    align-self: center;
}

.trainerName {
    margin-top: 5px;
    font-size: 16px;
}
