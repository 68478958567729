.chooseClientContainer {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-position: center;

    width: calc(100%);
    max-width: var(--max-page-width);
}

.chooseButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vh;
}