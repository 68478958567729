.workTimePageContainer {
    margin-bottom: var(--nav-bar-height);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-height: calc(100vh - var(--nav-bar-height));
    height: calc(100% - var(--nav-bar-height));
    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.dayContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch; /* Changed to make children the same height */
    margin: calc(1%); /* Centering horizontally and vertically */

    width: calc(100%);
    max-width: var(--max-page-width);

    border: 1px solid; /* Border around dayContainer */
}

.dayLeftPart {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: baseline;

    width: calc(80%); /* Adjusted width to occupy space inside dayContainer */
    border: 1px solid; /* Border around left part */
    height: 100%; /* Added to make the height 100% */

}

.dayLeftPart:hover {
    background-color: #f0f0f0; /* Example hover color */
}

.dayName {
}

.timeRanges {
    display: flex;
    flex-direction: row;
}

.timeRange {
    margin-right: 0.2rem;
}

.dayRightPart {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(20%);

    border: 1px solid; /* Border around right part */
}

.toggle {
}

.navBar {

}