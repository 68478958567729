.dateInputContainer {
    display: flex;
    flex-direction: column;
}

.label {
    color: #434343;
    font-weight: 500;
    font-size: 16px;
}

.input {
    background-color: #e2e1e1;
    color: #818181;

    border-radius: 8px;
    border: 1px solid #747171;

    width: 50%;

    flex-grow: 1;
    padding: 8px;
    box-sizing: border-box;

    font-weight: 400;
    font-size: 16px;
    text-align: right;
}