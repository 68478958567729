.cardContainer {
    height: 120px;
    width: 180px;
    background-size: cover;
    background-position: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    border: 0.1em solid #ccc;
    border-radius: 20px;
}

.categoryContainer {
    height: 30%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 0 0 20px 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.categoryName {
    width: 90%;
}
