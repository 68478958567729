.inputSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.label {
    color: #434343;
    font-weight: 500;
    font-size: 16px;
}

.input {
    background-color: #e2e1e1;
    color: #818181;

    min-height: 150px;
    max-height: 300px;

    border-radius: 20px;
    border: 1px solid #747171;

    padding: 10px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
}

.input.error {
    border: 1px solid #f4511e;
}