/* Модальное окно */
.modal {
    position: fixed;
    top: 0;
    left: 0;

    height: calc(100%);
    background-color: rgba(0, 0, 0, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100%);
    z-index: 1000; /* Высокий z-index для отображения поверх всего */
}

/* Содержимое модального окна */
.modalContent {
    background-color: white;
    border-radius: 4px;

    max-width: 340px;

    width: calc(100% - 4rem);
    padding: 1rem;
}

/* Заголовок модального окна */
.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalBody {
    display: flex;
    flex-direction: column;
}

/*Этот контейнер содержит в себе: контейнер начала, контейнер конца, кнопка удалить*/
.rangeContainer {
    margin-top: calc(1%);
    margin-bottom: calc(1%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

/*Этот контейнер содержит в себе: название и время*/
.timeContainer {
    display: flex;
    justify-content: space-between;
    width: calc(100%);
}

.rangeText {

}

.time {

}

.deleteButton {

}


/* Кнопка закрытия модального окна */
.modalHeader button {
    background: none;
    border: none;
    font-size: 1.5em;
}