.Nav {
    backdrop-filter: blur(18px);
    background-color: rgba(244, 244, 244, 0.8);

    position: fixed;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    height: var(--nav-bar-height);

    width: calc(100%);
    max-width: var(--max-page-width);
    border-top: 1px solid grey; /* Added border-top for grey line */

    padding: 5px 20px 5px 20px;
    box-sizing: border-box;
}

.NavItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: calc(100%);
    width: calc(100%);
}

.NavIcon {
    aspect-ratio: 1;
    object-fit: cover;
    width: 24px;
}

.NavText {
    font-size: var(--normal-text-size);
}