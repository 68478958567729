.MainContainer {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.optionalBlocks {
    width: calc(100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.expandedBlock {
    width: calc(100%);
    margin-top: 10px;
}

.expandedBlockHeader {
    width: calc(100%);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.expandedBlockTitle {
    color: #434343;
    font-size: 19px;
    margin: 0 0 5px 0;
}

.expandedBlockSection {
    width: calc(100%);
    display: flex;
    justify-content: center;
    align-items: center;
}


.whiteBoxContainer {
    width: 100%;
    height: calc(100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);

    padding: 15px;
    box-sizing: border-box;

    border-radius: 20px;
    gap: 15px;
}

.whiteBoxCard {
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;
    width: calc(100%);

    gap: 10px;
}

.editButtonContainer {
    width: 100%;
    display: flex;
    justify-content: right;
}


.categoryContainer {

}

.subCategory {

}

.subCategoryLabel {
    font-size: var(--normal-text-size);
    font-weight: bold;
}