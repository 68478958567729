
.trainersPageContainer {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    /*overflow-y: hidden;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: calc(100%);
}

.trainerList {
    width: calc(100%);
    max-width: var(--max-page-width);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
    box-sizing: border-box;
}