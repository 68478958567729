.searchBar {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 3%;
    background-color: rgba(226, 225, 225, 1);
    width: 100%;
    max-width: var(--max-page-width);
    align-items: center; /* Added to center the items vertically */
}

.searchInput {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.searchButton {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #2b2b2b;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
}