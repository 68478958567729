.MainContainer {
    margin-bottom: var(--padding-from-nav-bar);
    height: calc(100% - var(--nav-bar-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.Header {
    display: flex;
    flex-direction: column;
}

.ProfileTitle {
    color: #434343;
    font-size: var(--middle-text-size);
}

.ProfileInfo {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: var(--max-width-with-padding);
    max-width: var(--max-page-width);
}

.profileDetails {
    display: flex;
    justify-content: space-between;
    width: calc(100%);
}

.Name {
    margin: auto 0;
}

.settingsIcon {
    width: 24px;
    aspect-ratio: 1;
    margin: auto 0;
}

.balanceContainer {
    width: var(--max-width-with-padding);
    height: calc(100%);
    padding: 15px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.optionalBlocks {
    width: calc(100%);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.expandedBlock {
    width: calc(100%);
    margin-top: 10px;
}

.expandedBlockTitle {
    color: #434343;
    font-size: 19px;
    margin: 0 0 5px 0;
}

.expandedBlockSection {
    width: calc(100%);
}

.expandedBlockContent {
    width: calc(100%);
    max-width: var(--max-page-width);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
