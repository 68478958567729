
.registrationPage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-size: cover;
    background-position: center;

    max-width: var(--max-page-width);
    width: var(--max-width-with-padding);
    padding-left: var(--page-width-padding);
    padding-right: var(--page-width-padding);
}

.styledForm {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.photoSection {
    justify-content: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    color: #434343;
    text-align: center;
}



.photoInstruction {
    font-size: 16px; /* Relative to viewport width */
}

.textInputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.genderDateContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.genderContainer {
    display: flex;
    flex-direction: column;
}

.genderTitle {
    color: #434343;
    font-weight: 500;
    font-size: 16px;
}

.genders {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    border: 1px solid #747171;
    padding: 4px;
}

.genderOption {
    display: flex;
}

.genderRadio {
    margin-right: 8px;
}

.genderLabel {
    font-weight: 400;
    font-size: 16px;
}

.dateContainer {

}

.registrationButton {
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}