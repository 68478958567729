
.card {
    border-radius: 25px;
    box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);
    background-color: #fff;

    display: flex;
    color: #434343;

    gap: 10px;
    width: 100%;

    padding: 15px;
    box-sizing: border-box;
}

.photoContainer {
    width: 72px;
    height: 100%;

    display: flex;
    justify-content: center;

    margin: auto 0;
}

.trainerPhoto {
    border-radius: 50%;
    background-color: #e2e1e1;
    width: 72px;
    height: 72px;
}

.trainerInfoContainer {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    gap: 5px;
}

.nameAndRating {
    justify-content: space-between;
    display: flex;
    flex-direction: row;

    width: 100%;
    gap: 20px;
}

.nameContainer {
    align-items: center;
    justify-content: center;
}

.name {
    font-weight: bold;
    font-size: var(--normal-text-size);
    align-items: center;
}

.ratingContainer {
    display: flex;
    flex-direction: row;

    gap: 2px;
    align-items: center;
    justify-content: center;
}


.rating {
    font-size: var(--normal-text-size);
    font-weight: bold;
}


.starIcon {
    height: var(--normal-text-size);
    width: var(--normal-text-size);
}

.address {
    color: #818181;
    font-style: normal;
    font-size: var(--normal-text-size);
}

.activities {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.activity {
    justify-content: center;
    border-radius: 100px;
    border: 1px solid #434343;
    padding: 8px;
    font-size: var(--small-text-size);
    font-weight: 400;
}