.listContainer {
    display: flex;
    flex-direction: column;

    width: calc(100%);
    max-width: var(--max-page-width);

    border-top: 0.5px solid var(--black, #434343);
}

.serviceContainer {
    border-bottom: 0.5px solid var(--black, #434343);
    display: flex;
    flex-direction: column;
}

.selected {
    background-color: var(--grey, rgba(110, 107, 107, 0.18));
}

.titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-top: 5px;
    margin-bottom: 5px;
}

.title {
    color: var(--black, #434343);
    font-size: var(--normal-text-size);
    font-weight: 600;
}

.duration {
    color: var(--dark-gray, #818181);
    font-size: var(--small-text-size);

    margin-bottom: 5px;
}

.price {
    color: var(--black, #434343);
    font-size: var(--normal-text-size);
    font-weight: 600;
}