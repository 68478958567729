
.balanceCard {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(50, 50, 71, 0.06), 0px 4px 4px rgba(50, 50, 71, 0.08);

    padding: 15px;
    border-radius: 20px;

    height: 60px;
    width: calc(100%);
}

.accountTitle {
    color: #434343;
    font-size: 19px;
}

.balance {
    color: #434343;
    font-size: 23px;
}

.actionGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.historyButton {
    background: none;
    border: none;
    color: #818181;
    font-size: var(--small-text-size);
    cursor: pointer;
}

.rechargeGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 100%;
}

.recharge {
    background: none;
    border: none;
    color: #434343;
    font-size: 16px;
    cursor: pointer;
}

.rechargeIcon {
    height: 13px;
    width: 13px;
    aspect-ratio: 1;
}